//TODO download the ones I'm using and import locally
@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&display=swap');

body {
  margin: 0;
	background-color: var(--primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  display: block;
  white-space: pre-wrap   
}

#default-error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;

  & > * {
    margin: 1rem 0;
  }

  & > h3:nth-of-type(1) {
    font-size: 5rem;
  }

}