html {
	scroll-behavior: smooth;
	// --primary: #ACACE6;
	--primary: #4666FF;
	--accent: #FFA585;
	// --primary: #73C2FB;
	// --primary: #623CEA;
	// --secondary: #FEFE33;
	// --bg-color: #B0BABF;
	// --bg-color: #E9ECED;
	// --bg-color: #d2d8da;
	// --bg-color: #bbc4c7;
	// --bg-color: #a6afb3;
	cursor: url("/cursor.png"), auto;

	button, a, select {
		cursor: url("/pointer-sm.png"), pointer !important;
	}
}
// 1C05B3
// 0000CD
// 4666FF

@keyframes typeFlicker {
  0% {
		color: var(--accent);
	}
  50% {
		color: white;
	}
}

@keyframes loader {
	0% {
		transform: scale(1) rotate(0deg);
	}
	50% {
		transform: scale(1.5) rotate(180deg);
	}
	0% {
		transform: scale(1) rotate(360deg);
	}
}
